<!--
 * @Description:财务管理系统 开票记录管理 开票记录 finInvoiceRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 14:35:33
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!-- 上半部查询 -->
    <el-row class="topSelectTwoLine">
      <el-form :model="searchForm"
               :inline="true">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="开票时间">
              <el-date-picker v-model="time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="发票类型">
              <el-select clearable
                         filterable
                         v-model="searchForm.invoiceType"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="开票人账号">
              <el-input v-model="searchForm.userCode"
                        placeholder="请输入">

              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>开票记录</span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickDetails(scope.row)">详情</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium">
                    <a :href="$pdfBaseUrl+scope.row.pdfUrl"
                       target="_blank"
                       style="color:#019fe4">预览/下载</a>

                  </el-button>
                </el-col>
                <!-- <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickDownload(scope.row)">下载</el-button>
                </el-col> -->
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 详情显示 -->
    <el-dialog class="dialog"
               title="发票详情"
               append-to-body
               top:10vh
               :visible.sync="detailsDialog">
      <el-row class="text1">
        <span>发票编号：</span><span class="text2">{{detailTopList.invoiceSequence}}</span>
      </el-row>
      <el-row>
        <span class="text1">开票信息</span>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span class="text3">发票抬头：</span><span class="text4">{{detailTopList.buyerName}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">税号：</span><span class="text4">{{detailTopList.buyerTaxNum}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">开户行：</span><span class="text4">{{detailTopList.c_bankAccount}}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span class="text3">注册地址：</span><span class="text4">{{detailTopList.c_address}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">发票类型：</span><span class="text4">{{detailTopList.invoiceTypeDesc}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">开票人账号：</span><span class="text4">{{detailTopList.userCode}}</span>
        </el-col>
      </el-row>
      <el-row>
        <span class="text3">开票备注：</span><span class="text4">{{detailTopList.c_remark}}</span>
      </el-row>
      <el-row>
        <span class="text3">开票金额：</span><span class="text5">{{detailTopList.money}}元</span>
      </el-row>
      <el-row class="text1">
        <span>开票订单</span>
      </el-row>
      <!-- 订单内容 -->
      <el-row class="tableContent">
        <dt-table :tableColumnList="dialogTableColumnList"
                  :data="detailsDataList"
                  :map="dialogListMap"
                  :tableConfig='dialogTableConfig'
                  :paginationConfig='dialogPaginationConfig'
                  @getList="dialogGetList"></dt-table>
      </el-row>
    </el-dialog>
    <!-- 预览发票 -->
    <!-- <el-dialog title="发票预览"
               append-to-body
               top:10vh
               :visible.sync="invoiceVisible">
      <img class="previewImgClass"
           :src="$pdfBaseUrl + previewImg"
           alt="">
    </el-dialog> -->
    <!-- 下载发票 -->
    <!-- <a :href="downLoadPdf"></a> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      // 表头
      tableColumnList: [
        {
          prop: 'invoiceSequence',
          label: '发票编号',
        },
        {
          prop: 'money',
          label: '发票金额（元）',
        },
        {
          prop: 'invoiceTypeDesc',
          label: '开票类型',
        },
        {
          prop: 'invoiceDate',
          label: '开票时间',
        },
        {
          prop: 'type',
          label: '发票类型',
        },
        {
          prop: 'userCode',
          label: '开票人账号',
        },
        {
          prop: 'userName',
          label: '开票人',
        },
      ],
      listMap: { pageNum: 1, pageSize: 15, },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      //表格数据
      tableList: { list: [], },
      searchForm: {},
      pageNum: 1,//分页
      pageSize: 15,//分页
      // 弹窗表头
      dialogTableColumnList: [
        {
          prop: 'orderSequence',
          label: '支付流水号',
        },
        {
          prop: 'paymentTime',
          label: '支付时间',
        },
        {
          prop: 'payMethod',
          label: '支付渠道',
        },
      ],
      //表格数据
      dialogListMap: { pageNum: 1, pageSize: 5, },
      dialogTableConfig: { border: true },
      dialogPaginationConfig: { layout: 'prev, pager, next,jumper', total: 0 },
      dialogSearchForm: {},
      dialogPagesize: 5,
      dialogPageNum: 1,
      // 日历快捷
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      invoiceSequence: '',//编号
      time: null,
      typeList: [], //发票类型
      invoiceVisible: false,//查看发票详情显示
      previewImg: "",//图片预览地址
      downLoadPdf: "", //下载图片
      detailsDialog: false,//详情弹窗显示
      detailTopList: {},//详情上半部分表单
      detailsDataList: { list: [] },//详情表格
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryInvoiceType()
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 列表查询
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$finInvoiceRecord.queryInvoiceRecord(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.tableList.list.forEach(item => {
          item.money = Number(item.money).toFixed(2)
        });
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    onSubmit () {
      this.pageNum = 1;
      this.listMap.pageNum = 1
      if (this.time) {
        this.searchForm.startTime = this.time[0]
        this.searchForm.endTime = this.time[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.time = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 点击详情
    handleClickDetails (row) {
      this.invoiceSequence = ""
      this.detailTopList = row
      this.invoiceSequence = row.invoiceSequence
      this.detailsDialog = true
      this.queryDialogTable()
    },
    // 详情中的表格渲染
    queryDialogTable () {
      this.dialogSearchForm['pageNum'] = this.dialogPageNum;
      this.dialogSearchForm['pageSize'] = this.dialogPagesize;
      this.dialogSearchForm['invoiceSequence'] = this.invoiceSequence
      this.$finInvoiceRecord.getPaymentRecordByInvoice(this.dialogSearchForm).then(res => {
        this.detailsDataList.list = res.resultEntity.content
        this.dialogPaginationConfig.total = res.resultEntity.totalElements
      })
    },
    //弹窗表格分页
    dialogGetList (value) {
      this.dialogPageNum = value.pageNum
      this.queryDialogTable()
    },
    // 点击下载
    handleClickDownload (row) {
      let info = {
        fpqqlsh: row.invoiceSequence
      }
      this.$finInvoiceRecord.getInvoiceAgain(info).then(res => {
        if (JSON.parse(res.resultEntity).list[0].c_msg !== "开票完成（最终状态）") {
          this.$notify.info({
            title: '提示',
            message: '开票中请稍后'
          });
        } else {
          // c_url pdf
          // this.downLoadPdf = JSON.parse(res.resultEntity).list[0].c_url
          // download(this.downLoadPdf);
          // window.location.href = this.downLoadPdf
          // let url = window.URL.createObjectURL(new Blob([this.downLoadPdf]));
          // let link = document.createElement('a');
          // link.style.display = 'none';
          // link.href = url;
          // link.setAttribute('download', "fapiao.pdf");
          // document.body.appendChild(link);
          // link.click()
        }
      })
    },
    // 查询发票类型
    queryInvoiceType () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '4324HJKH31J123TBH1234L12K31341',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.typeList = res.resultEntity
      })
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    min-height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // min-height: calc(100% - #{$topSelectHeight} - 16px);
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      line-height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
  .Fbutton {
    margin-left: 17px;
  }
}
.dt-width {
  width: 63%;
}
.previewImgClass {
  height: 100%;
  width: 100%;
}
.dialog .el-row {
  padding-top: 12px;
}
.text1 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #f8fdff;
}
.text2 {
  font-weight: bold;
}
.text3 {
  color: #70aee8;
}
.text4 {
  color: #bae8ff;
}
.text5 {
  font-size: 24px;
  color: #e07d26;
}
</style>